<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">Overview</h4>
    </b-card-header>

    <!-- apex chart -->
    <vue-apex-charts
      type="radialBar"
      height="220"
      :options="goalOverviewRadialBar.chartOptions"
      :series="goalOverviewRadialBar.series"
    />
    <b-row class="text-center mx-0">
      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> TOTAL CASES </b-card-text>
        <div v-if="processTrackerCount !== null">
          <h3 class="font-weight-bolder mb-50">
            <!-- {{
              $store.state.matterStore.trackerCount[0].count +
              $store.state.matterStore.trackerCount[1].count
            }} -->
            {{ processTrackerCount.pending + processTrackerCount.disposed }}
          </h3>
        </div>
      </b-col>

      <b-col
        cols="4"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> PENDING </b-card-text>
        <h3
          class="font-weight-bolder mb-50"
          v-if="processTrackerCount !== null"
        >
          {{ processTrackerCount.pending }}
        </h3>
      </b-col>

      <b-col
        cols="4"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0"> DISPOSED </b-card-text>
        <h3
          class="font-weight-bolder mb-50"
          v-if="processTrackerCount !== null"
        >
          {{ processTrackerCount.disposed }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol
  },
  data() {
    return {
      processTrackerCount: null,
      goal_overview: {},
      goalOverviewRadialBar: {
        series: [0],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1
            }
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%'
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%'
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem'
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '2rem'
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          grid: {
            padding: {
              bottom: 30
            }
          },
          labels: ['Closed Cases']
        }
      }
    }
  },
  // computed: {
  //   processTrackerCount() {
  //     const trackerCount = this.$store.state.matterStore.trackerCount

  //     if (!trackerCount) return null

  //     let pendingCount = 0
  //     let disposedCount = 0

  //     trackerCount.forEach((item) => {
  //       const { case_status, count } = item

  //       // Group statuses into 'Pending'
  //       if (
  //         case_status === 'PENDING' ||
  //         case_status === 'TRANSFER' ||
  //         case_status === 'ORDER RESERVED'
  //       ) {
  //         pendingCount += count
  //       }
  //       // Group other statuses into 'Disposed'
  //       else {
  //         disposedCount += count
  //       }
  //     })

  //     return {
  //       pending: pendingCount,
  //       disposed: disposedCount
  //     }
  //   }
  // },
  // mounted() {
  //   this.mergedData = this.processTrackerCount
  // },
  created() {
    this.$store.dispatch('matterStore/fetchTrackerCount').then((response) => {
      const trackerCount = this.$store.state.matterStore.trackerCount

      if (trackerCount !== null && trackerCount.length > 0) {
        // Initialize pending and disposed counts
        let pendingCount = 0
        let disposedCount = 0

        // Process each item in trackerCount and group into pending or disposed
        trackerCount.forEach((item) => {
          const { case_status, count } = item

          // Group statuses into 'Pending'
          if (
            case_status === 'PENDING' ||
            case_status === 'TRANSFER' ||
            case_status === 'ORDER RESERVED'
          ) {
            pendingCount += count
          } else {
            disposedCount += count
          }
        })

        // Calculate total cases
        const totalCases = pendingCount + disposedCount

        this.processTrackerCount = {
          pending: pendingCount,
          disposed: disposedCount
        }

        // Calculate the percentage for the radial bar (pending vs. disposed)
        let perc = parseInt((disposedCount * 100) / totalCases)

        // Log and set the radial bar series with the calculated percentage
        console.log('PERC -->', perc)
        this.goalOverviewRadialBar.series = [perc]
      }
    })
  }
}
</script>
