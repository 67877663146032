<template>
  <div>
    <b-badge
      :variant="loading ? 'secondary' : 'primary'"
      class="badge-round"
      @click="onDownload"
      :disabled="loading"
    >
      <feather-icon
        :id="`hearing-download`"
        :icon="loading ? 'LoaderIcon' : 'DownloadIcon'"
        size="16"
        class="cursor-pointer"
        :class="{ 'rotate-icon': loading }"
      />
      <span class="cursor-pointer px-50">{{ loading ? 'Downloading...' : 'Download' }}</span>
    </b-badge>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BButton,
    BBadge,
  },
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    onDownload() {
      if (this.loading) return

      this.loading = true
      console.log('Dates: ', this.selectedDates)
      console.log('Courts: ', this.selectedCourts)

      store.dispatch(
        'hearingStore/downloadHearing',
        {ctx: this}
      ).then((response) => {
          if(response != null && response.success){
            this.downloadAs(response.url, `filtered-causelist.pdf`)
          }
      }).finally(() => {
          this.loading = false
      })
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.rotate-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
